import { useMemo } from 'react';
import {
    InfiniteData,
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
    availableEventToEvent,
    eventAssetToEventAssetDto,
    eventStaffToEventStaffDto,
    getEventItemDtoToEvent,
} from './event.converters';
import { EventAsset, EventItemMinimal, EventStaff } from './event.types';
import {
    type CancelEventsRes1Dto,
    type CancelEventsResDto,
    EventsService,
    EventStatus,
    GetEventRes1Dto,
    GetEventResDto,
    GetEventsResDto,
    GetEventsResItem0BookingsItem0Dto,
    GetEventsResItem0Dto,
    GetEventsResItem1Dto,
    PaxData,
    ProductType,
    UpdateAssetsEventsReq1Dto,
    UpdateAssetsEventsReqDto,
    UpdateStaffEventsReq1Dto,
    UpdateStaffEventsReqDto,
} from '../../requests';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
} from '../common.types';
import { convertItemsToActionItemsDto } from '../common.converters';
import { useAvailability } from '../availability/availability.hooks';

export interface UseEventsLazyData {
    types?: ProductType[];
    statuses?: EventStatus[];
    action?: 'created' | 'last_updated' | 'deleted';
    at_start?: number;
    at_end?: number;
    by?: string;
    text_search?: string;
    startTs?: number;
    endTs?: number;
    pageNumber?: number;
    pageSize?: number;
    bookings?: boolean;
}

export const useDayProductEvents = (
    params: {
        day: number;
        eventId?: string;
        productId: string;
        pax: PaxData;
    },
    enabled = true
) => {
    const { data, ...other } = useAvailability(
        {
            requestBody: {
                product: { value: params.productId, label: '' },
                dates: {
                    startDate: new Date(params.day),
                    endDate: new Date(params.day),
                },
                pax: params.pax,
                events: params.eventId
                    ? [
                          {
                              id: params.eventId,
                              exclude: true,
                          },
                      ]
                    : undefined,
            },
        },
        {
            enabled:
                enabled && !!params.productId && !!params.day && !!params.pax,
        }
    );

    const parsedData: EventItemMinimal[] | undefined = useMemo(() => {
        return data?.events?.[0]?.products?.[0].events.map(event =>
            availableEventToEvent(event, data?.products[event.key])
        );
    }, [data]);

    return {
        data: parsedData,
        ...other,
    };
};

export const useEventsLazyKey = 'useEventsLazyKey';

export const useEventsLazy = (
    params: UseEventsLazyData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof EventsService.getEvents>
    > & { onInvalidPagination?: () => void } = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 5;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useEventsLazyKey, params],
        retry: false,
        queryFn: async ({ pageParam }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.getEvents(
                authorization,
                params.action,
                params.at_start,
                params.at_end,
                undefined,
                params.by,
                params.types,
                params.statuses,
                params.startTs,
                params.endTs,
                params.text_search,
                pageParam?.page || 0,
                pageSize,
                pageParam?.startAtId || undefined,
                true
            );
        },
        getNextPageParam: lastPage => {
            if (!lastPage.items || !lastPage.next_id) return undefined;
            return {
                startAtId: lastPage.next_id,
                page: lastPage.next_page_number,
            };
        },
        cacheTime: 0,
        ...options,
        onError: (error: any) => {
            if (error?.status === 409) {
                options.onInvalidPagination?.();
            } else options.onError?.(error);
        },
    });

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getEventItemDtoToEvent)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useUpdateEventSilent = () => {
    const queryClient = useQueryClient();
    const queriesData = queryClient.getQueriesData({
        queryKey: [useEventsLazyKey],
        exact: false,
    });

    return (
        eventId: string,
        callback: (b: GetEventsResItemDto) => GetEventsResItemDto
    ) => {
        queriesData.forEach(([queryKey]) => {
            queryClient.setQueryData<InfiniteData<GetEventsResDto>>(
                queryKey,
                events => {
                    if (!events) return undefined;
                    const pages = events.pages.map(page => {
                        const items =
                            page.items?.map(ev =>
                                ev.id === eventId ? callback(ev) : ev
                            ) || [];
                        return { ...events, items };
                    });
                    return { ...events, pages };
                }
            );
        });
    };
};

type GetEventsResItemDto = GetEventsResItem0Dto | GetEventsResItem1Dto;

export const useUpdateEventBooking = () => {
    const updateEvent = useUpdateEventSilent();

    return (
        eventId: string,
        bookingId: string,
        callback: (
            b: GetEventsResItem0BookingsItem0Dto
        ) => GetEventsResItem0BookingsItem0Dto
    ) => {
        updateEvent(eventId, event => {
            const bookings =
                event?.bookings.map(b =>
                    b.id === bookingId ? callback(b) : b
                ) || [];
            return {
                ...event,
                bookings_summary: {
                    ...event,
                    bookings,
                },
            };
        });
    };
};

export const useEventKey = 'useEventKey';
export const useEvent = (
    id?: string,
    options: CustomQueryOptions<ReturnType<typeof EventsService.getEvent>> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useEventKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.getEvent(id as string, authorization);
        },
        enabled: !!id,
        ...options,
    });

    const parsedData = useMemo(() => {
        return data ? getEventItemDtoToEvent(data) : undefined;
    }, [data]);

    return {
        data: parsedData,
        ...other,
    };
};

type EventDto =
    | GetEventsResItem0Dto
    | GetEventsResItem1Dto
    | GetEventResDto
    | GetEventRes1Dto;

const mergeItems = (
    item: EventDto,
    newItem: CancelEventsResDto | CancelEventsRes1Dto
    // @ts-ignore-next-line
): EventDto => ({
    ...item,
    ...newItem,
    // @ts-ignore-next-line
    bookings: !newItem.bookings?.length ? item.bookings : newItem.bookings,
});

export const useEventCache = () => {
    const queryClient = useQueryClient();

    const update = (
        id: string,
        newItem: CancelEventsResDto | CancelEventsRes1Dto
    ) => {
        queryClient
            .getQueriesData({
                queryKey: [useEventsLazyKey],
                exact: false,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<InfiniteData<GetEventsResDto>>(
                    queryKey,
                    data => {
                        if (!data?.pages) return undefined;
                        const pages = data.pages.map(page => {
                            const items =
                                page.items?.map(item =>
                                    item.id === id
                                        ? (mergeItems(item, newItem) as
                                              | GetEventsResItem0Dto
                                              | GetEventsResItem1Dto)
                                        : item
                                ) || [];
                            return { ...data, items };
                        });
                        return { ...data, pages };
                    }
                );
            });
        queryClient
            .getQueriesData({
                queryKey: [useEventKey, id],
                exact: true,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<EventDto>(queryKey, data => {
                    return data
                        ? (mergeItems(data, newItem) as
                              | GetEventResDto
                              | GetEventRes1Dto)
                        : undefined;
                });
            });
    };

    return {
        update,
    };
};

export const useUpdateEventOperations = (
    oldAssets: EventAsset[],
    oldStaff: EventStaff[],
    productType: ProductType,
    oldCapacityId: string,
    options: CustomMutationOptions<
        {
            assets: EventAsset[];
            staff: EventStaff[];
            capacityId: string;
            eventId: string;
        },
        | ReturnType<typeof EventsService.updateAssetsEvents>
        | ReturnType<typeof EventsService.updateStaffEvents>
        | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useEventCache();

    return useMutation({
        mutationFn: async ({ assets, staff, eventId, capacityId }) => {
            const requestAssets = convertItemsToActionItemsDto(
                oldAssets.map(eventAssetToEventAssetDto),
                assets.map(eventAssetToEventAssetDto),
                'id'
            );
            let response: CancelEventsResDto | CancelEventsRes1Dto | null =
                null;

            if (capacityId && capacityId !== oldCapacityId) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                response = await EventsService.updateCapacityEvents(
                    eventId,
                    capacityId,
                    authorization
                );
            }

            if (requestAssets.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                response = await EventsService.updateAssetsEvents(
                    eventId,
                    authorization,
                    {
                        items: requestAssets,
                        type: productType as unknown as
                            | UpdateAssetsEventsReqDto.type.TOUR
                            | UpdateAssetsEventsReq1Dto.type.TRANSFER,
                    }
                );
            }

            const requestStaff = convertItemsToActionItemsDto(
                oldStaff.map(eventStaffToEventStaffDto),
                staff.map(eventStaffToEventStaffDto),
                'id'
            );

            if (requestStaff.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                response = await EventsService.updateStaffEvents(
                    eventId,
                    authorization,
                    {
                        items: requestStaff,
                        type: productType as unknown as
                            | UpdateStaffEventsReqDto.type.TOUR
                            | UpdateStaffEventsReq1Dto.type.TRANSFER,
                    }
                );
            }
            return response;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].eventId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useCancelEvent = (
    options?: CustomMutationOptions<
        {
            id: string;
            reason?: string;
        },
        ReturnType<typeof EventsService.cancelEvents>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        async ({ id, reason }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.cancelEvents(id, authorization, {
                message: reason || '',
            });
        },
        {
            ...options,
            onSuccess: (...args) => {
                // TODO update event in cache
                options?.onSuccess?.(...args);
            },
        }
    );
};
