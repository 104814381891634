import React, { useCallback, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import {
    CreatableTags,
    hasDirtyFields,
    SearchSelect,
    TagsList,
    TextField,
    useForm,
} from '@travelity/form';
import {
    Box,
    Divider,
    Skeleton,
    Stack,
    Typography,
    IconButton as MuiIconButton,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Check from '@mui/icons-material/Check';
import {
    convertNameDtoToFullName,
    Country,
    Customer,
    Language,
    Nationality,
    useAddCustomer,
    useCustomer,
    useUpdateCustomer,
} from '@travelity/api';
import { array, mixed, object, string } from 'yup';
import {
    Card,
    IconButton,
    IconButtonGroup,
    LoadingOverlay,
} from '@travelity/ui';
import Edit from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import { convertEnumToList, emailRefEx } from '@travelity/utils';
import Attachments from '@travelity/form/src/controls/attachments/attachments';
import { useSnackbar } from 'notistack';

import { DataRow } from '../data-row';
import { SingleLineTextField } from '../booking-participants/components/single-line-text-field';
import { TravelerForm } from '../booking-participants/booking-participants.types';
import { customerFormToCustomer } from '../booking-participants/booking-participants.converters';
import { useIsChanged } from '../../hooks';

export interface CustomerModalProps {
    onClose: () => void;
    onAdd: (v: Partial<Customer>) => void;
    open: boolean;
    customerId?: string;
}

const schema = object().shape({
    fullName: string().required(),
    emails: array().when('numbers', {
        is: (numbers?: string[]) => !numbers?.length,
        then: sch =>
            sch.min(1, 'Either phone number or email must be specified'),
    }),
    numbers: array(),
    birthPlace: string(),
    birthDate: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    nationality: mixed<Nationality>()
        .nullable()
        .transform((curr, orig) =>
            !orig || orig === '' ? null : curr.toLowerCase()
        )
        .oneOf(Object.values(Nationality)),
    passportN: string().when(
        ['citizenship', 'issuedBy', 'issuedAt', 'expiresAt'],
        {
            is: (citizenship?: string, ...optionals: (string | undefined)[]) =>
                !citizenship && optionals.find(a => !!a),
            then: sch =>
                sch.required(
                    'Either passport number or citizenship must be specified'
                ),
        }
    ),
    issuedBy: string(),
    issuedAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    expiresAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    citizenship: mixed<string>()
        .nullable()
        .transform((curr, orig) =>
            !orig || orig === '' ? null : curr.toLowerCase()
        )
        .oneOf(Object.values(Country)),
});

export const CustomerModal: React.FC<CustomerModalProps> = ({
    onClose,
    onAdd,
    open,
    customerId,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const languages = useMemo(() => convertEnumToList<Language>(Language), []);

    const [editing, setEditing] = React.useState(!customerId);

    const { data: customer, isLoading } = useCustomer(customerId, {
        enabled: !!customerId && open,
    });
    const isCustomerLoading = isLoading && !!customerId;

    const { mutate: addCustomer, isLoading: addingCustomer } = useAddCustomer({
        onSuccess: data => {
            onAdd?.({
                id: data.id,
                fullName: convertNameDtoToFullName(data.name),
            });
            enqueueSnackbar('New customer profile created', {
                variant: 'success',
            });
        },
    });

    const { mutate: updateCustomer, isLoading: updatingCustomer } =
        useUpdateCustomer(customer, {
            onSuccess: () => {
                setEditing(false);
            },
            onError: error => {
                // @ts-ignore
                if (error?.status !== 409) {
                    enqueueSnackbar('Failed to update the customer', {
                        variant: 'error',
                    });
                }
            },
        });

    const {
        Form,
        formState: { isDirty, dirtyFields },
        handleSubmit,
        watch,
        trigger,
        reset,
        getValues,
    } = useForm<TravelerForm>({
        onSubmit: useCallback(() => {}, []),
        schema,
        mode: 'onChange',
    });

    useEffect(() => {
        if (customer) {
            // TODO reset undefined
            reset({ ...getValues(), ...customer });
        }
    }, [customer]);

    const onSubmit = handleSubmit(item => {
        if (!customerId) {
            // Customer Creation
            addCustomer({
                customer: customerFormToCustomer({
                    ...item,
                    id: item.customerId,
                    attachments: item.attachments || [],
                    fullName: item.fullName,
                } as Customer),
                // @ts-ignore
                attachments: item.attachments || [],
            });
        } else {
            // Customer Update
            const { attachments, ...customerFields } = dirtyFields;
            updateCustomer({
                id: customerId,
                customer: hasDirtyFields(customerFields)
                    ? customerFormToCustomer({
                          ...item,
                          id: item.customerId,
                          attachments: item.attachments || [],
                          fullName: item.fullName,
                      } as Customer)
                    : undefined,
                attachments: item.attachments,
            });
        }
    });

    useEffect(() => {
        if (customer) {
            reset(customer, { keepValues: true });
        }
    }, [customer, reset]);

    const emails = customer?.emails.map(({ value }) => value) || [];
    const phoneNumbers =
        customer?.numbers.map(({ value }) => `+${value}`) || [];
    const numbers = watch('numbers');
    const phoneNumberChange = useIsChanged(numbers);
    useEffect(() => {
        if (phoneNumberChange) {
            trigger('emails');
        }
    }, [phoneNumberChange, numbers]);

    const saving = addingCustomer || updatingCustomer;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-container': { justifyContent: 'end' },
            }}
            PaperProps={{
                sx: {
                    px: 4,
                    py: 8,
                    bgcolor: 'transparent',
                    alignItems: 'flex-end',
                    boxShadow: 'none',
                    m: 0,
                },
            }}
        >
            <MuiIconButton sx={{ mr: 5, color: '#000' }} onClick={onClose}>
                <Close fontSize="small" />
            </MuiIconButton>
            <Card
                sx={{
                    width: '379px',
                    minHeight: '116px',
                    transition: 'max-height 0.2s ease-in',
                    pb: 2,
                    boxShadow:
                        '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
                }}
                // component={PerfectScrollbar}
                parentProps={{ sx: { opacity: saving ? 0.7 : 1 } }}
                buttons={
                    <IconButtonGroup flexShrink={0} sx={{ zIndex: 1 }}>
                        {!editing && (
                            <IconButton
                                icon={<Edit fontSize="small" />}
                                onClick={() => setEditing(true)}
                                tooltip="Edit"
                                tooltipPlacement="right"
                                disabled={saving}
                            />
                        )}
                        {editing && (
                            <>
                                <IconButton
                                    icon={<Check fontSize="small" />}
                                    color="primary.main"
                                    hoverColor="primary.dark"
                                    onClick={onSubmit}
                                    disabled={!isDirty || saving}
                                    tooltip="Save"
                                    data-testid="save"
                                />
                                <Divider sx={{ mx: 1 }} />
                                <IconButton
                                    icon={<Close fontSize="small" />}
                                    color="error.main"
                                    hoverColor="error.dark"
                                    onClick={() => {
                                        if (!customerId) onClose();
                                        setEditing(false);
                                    }}
                                    tooltip="Cancel"
                                    data-testid="cancel"
                                />
                            </>
                        )}
                    </IconButtonGroup>
                }
            >
                <>
                    <Form>
                        <Box
                            sx={{
                                py: '22px',
                                px: 0.75,
                                color: '#9298ab',
                                bgcolor: '#f7f8fa',
                                height: '36px',
                                fontWeight: 600,
                                borderRadius: '12px 12px 0px 0px',
                                lineHeight: '10px',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {editing && !customerId ? (
                                <Stack direction="row" sx={{ width: 1 }}>
                                    <TextField
                                        sx={{ mx: '10px' }}
                                        autoFocus
                                        InputProps={{
                                            sx: { fontSize: '14px', pl: 0.5 },
                                        }}
                                        placeholder="Full Name"
                                        name="fullName"
                                        variant="standard"
                                        showErrors
                                        helperText=""
                                        fullWidth
                                    />
                                </Stack>
                            ) : (
                                <Stack
                                    direction="row"
                                    sx={{ width: 1, pl: '10px' }}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    {isCustomerLoading ? (
                                        <Skeleton width={200} height={20} />
                                    ) : (
                                        <Box component="span">
                                            {customer?.fullName}
                                        </Box>
                                    )}
                                </Stack>
                            )}
                        </Box>
                        <Stack
                            gap={0.5}
                            p={2}
                            pb={0}
                            component={PerfectScrollbar}
                            sx={{ maxHeight: '428px' }}
                        >
                            <>
                                <Divider
                                    sx={{
                                        mt: editing ? 1 : 0,
                                    }}
                                >
                                    Contact Details
                                </Divider>
                                {editing ? (
                                    <>
                                        <Typography
                                            sx={{
                                                color: '#2B395B',
                                                fontSize: '12px',
                                            }}
                                        >
                                            Emails
                                        </Typography>
                                        <CreatableTags
                                            name="emails"
                                            regEx={emailRefEx}
                                            inputProps={{
                                                size: 'small',
                                                sx: {
                                                    '&& .MuiInputBase-root': {
                                                        paddingRight: '10px',
                                                    },
                                                },
                                                placeholder:
                                                    'Type email and press enter',
                                            }}
                                            showErrors
                                        />
                                        <Typography
                                            sx={{
                                                color: '#2B395B',
                                                fontSize: '12px',
                                            }}
                                        >
                                            Phone Numbers
                                        </Typography>
                                        <CreatableTags
                                            name="numbers"
                                            regEx={/^\+?\d+ \d+$/}
                                            inputProps={{
                                                size: 'small',
                                                sx: {
                                                    '&& .MuiInputBase-root': {
                                                        paddingRight: '10px',
                                                    },
                                                },
                                                placeholder:
                                                    'Type number and press enter',
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Stack
                                        direction="column"
                                        gap={2}
                                        sx={{ pt: 1 }}
                                    >
                                        <DataRow
                                            label="Emails"
                                            value={emails.join(', ')}
                                            isLoading={isCustomerLoading}
                                        />
                                        <DataRow
                                            label="Phone Numbers"
                                            value={phoneNumbers.join(', ')}
                                            isLoading={isCustomerLoading}
                                        />
                                    </Stack>
                                )}
                                {editing && (
                                    <>
                                        <Divider sx={{ mt: 1, mb: 0.5 }}>
                                            Languages
                                        </Divider>
                                        <SearchSelect
                                            placeholder="Select Language"
                                            name="languages"
                                            multiple
                                            label=""
                                            size="small"
                                            width="100%"
                                            options={languages}
                                        />
                                    </>
                                )}
                                {!editing && !!customer?.languages?.length && (
                                    <>
                                        <Divider sx={{ mt: 1, mb: 0.5 }}>
                                            Languages
                                        </Divider>
                                        <TagsList
                                            multiple
                                            sx={{
                                                ml: -1,
                                                mb: -0.5,
                                            }}
                                            tagSx={{
                                                opacity: 1,
                                                py: 0.75,
                                                bgcolor:
                                                    'rgba(85, 181, 207, 0.2)',
                                                color: '#9198AA',
                                            }}
                                            name="languages"
                                            selectAll={false}
                                            disabled
                                            hideUnselected
                                            options={languages}
                                        />
                                    </>
                                )}
                                <Divider sx={{ mt: 1.5 }}>
                                    Personal Information
                                </Divider>
                                <SingleLineTextField
                                    label="Date of Birth"
                                    name="birthDate"
                                    width="138px"
                                    readOnly={!editing}
                                    isLoading={isCustomerLoading}
                                />
                                <SingleLineTextField
                                    label="Place of Birth"
                                    name="birthPlace"
                                    width="138px"
                                    readOnly={!editing}
                                    isLoading={isCustomerLoading}
                                />
                                <SingleLineTextField
                                    label="Nationality"
                                    name="nationality"
                                    width="138px"
                                    readOnly={!editing}
                                    isLoading={isCustomerLoading}
                                />
                                <Divider sx={{ mt: 1.5 }}>
                                    Identification Document
                                </Divider>
                                <Stack direction="row" gap={1}>
                                    <Box sx={{ width: 0.5 }}>
                                        <SingleLineTextField
                                            label="Passport №"
                                            name="passportN"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                        <SingleLineTextField
                                            label="Issued at"
                                            name="issuedAt"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                        <SingleLineTextField
                                            label="Citizenship"
                                            name="citizenship"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                    </Box>
                                    <Box sx={{ width: 0.5 }}>
                                        <SingleLineTextField
                                            label="Issued by"
                                            name="issuedBy"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                        <SingleLineTextField
                                            label="Expires at"
                                            name="expiresAt"
                                            readOnly={!editing}
                                            isLoading={isCustomerLoading}
                                        />
                                    </Box>
                                </Stack>
                                {(!!customer?.attachments?.length ||
                                    editing) && (
                                    <>
                                        <Divider sx={{ mt: 1.5 }}>
                                            Attachments
                                        </Divider>
                                        <Attachments
                                            readOnly={!editing}
                                            name="attachments"
                                        />
                                    </>
                                )}
                            </>
                        </Stack>
                    </Form>
                    {saving && <LoadingOverlay />}
                </>
            </Card>
        </Dialog>
    );
};
