import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useMemo, useState } from 'react';
import {
    OrdersService,
    DiscountType,
    GetOrderResDto,
    type GetOrderResBookingsItem0Dto,
    type GetOrderResBookingsItem1Dto,
    OrderStatus,
    BookingsService,
} from '../../requests';
import { getOrderItemDtoToOrder } from './order.converters';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
} from '../common.types';
import {
    BookingPriceParams,
    BookingPriceSummary,
} from '../booking/booking.types';
import {
    convertBookingPriceParamsAndPriceResponseToBookingPriceSummary,
    convertBookingPriceParamsToCreateBookingReqItemDto,
} from '../booking/booking.converters';

export enum RequestTypes {
    EVENT = 'event',
    ORDER = 'order',
}

export const useCancelOrder = (
    options?: CustomMutationOptions<
        { orderId: string; reason?: string },
        ReturnType<typeof OrdersService.cancelOrders>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ orderId, reason }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.cancelOrders(orderId, authorization, {
                message: reason || '',
            });
        },
        ...options,
        onSuccess: (...args) => {
            // TODO update order in cache
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdatePrice = (
    params?: BookingPriceParams,
    initialPrice?: {
        total: number;
        subtotal: number;
    },
    options?: CustomMutationOptions<
        BookingPriceParams,
        ReturnType<typeof BookingsService.computePriceBookings>
    >
) => {
    const [state, setState] = useState<{
        data?: BookingPriceSummary;
        params?: BookingPriceParams;
        isLoading: boolean;
        discount: number;
        discountType: DiscountType;
        options: string[];
    }>({
        data: params
            ? {
                  ...convertBookingPriceParamsAndPriceResponseToBookingPriceSummary(
                      params
                  ),
                  beforeDiscount: initialPrice?.subtotal || 0,
                  final: initialPrice?.total || 0,
              }
            : undefined,
        params,
        isLoading: true,
        discount: 0,
        discountType: DiscountType.RELATIVE,
        options: [],
    });
    const { getAccessTokenSilently } = useAuth0();
    const { mutate: getPrices } = useMutation({
        mutationFn: async priceParams => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.computePriceBookings(
                authorization,
                convertBookingPriceParamsToCreateBookingReqItemDto(priceParams)
            );
        },
        ...options,
        onSuccess: (data, priceParams) => {
            setState(prevData => ({
                ...prevData,
                data: convertBookingPriceParamsAndPriceResponseToBookingPriceSummary(
                    priceParams,
                    data
                ),
                isLoading: false,
            }));
        },
    });

    const needsUpdate = useMemo(() => {
        const initialPriceValid =
            params &&
            initialPrice &&
            !params?.options.length &&
            !params?.discount?.amount;
        const oldParams = state.params
            ? JSON.stringify(
                  convertBookingPriceParamsToCreateBookingReqItemDto(
                      state.params
                  )
              )
            : '';
        const newParams = params
            ? JSON.stringify(
                  convertBookingPriceParamsToCreateBookingReqItemDto(params)
              )
            : '';

        return !initialPriceValid && oldParams !== newParams;
    }, [state.params, params]);

    React.useEffect(() => {
        if (
            params &&
            initialPrice &&
            !params?.options.length &&
            !params?.discount?.amount
        ) {
            setState({
                data: {
                    ...convertBookingPriceParamsAndPriceResponseToBookingPriceSummary(
                        params
                    ),
                    beforeDiscount: initialPrice.subtotal,
                    final: initialPrice.total,
                },
                params,
                isLoading: false,
                discount: 0,
                discountType: DiscountType.RELATIVE,
                options: [],
            });
        }
    }, [params, initialPrice]);

    const update = useCallback(() => {
        if (params?.pax) {
            setState(prevState => ({
                ...prevState,
                isLoading: true,
                params,
            }));
            getPrices(params);
        }
    }, [params, getPrices]);

    return {
        data: state.data,
        isLoading: state.isLoading,
        changed: needsUpdate,
        update,
    };
};

export interface UseOrdersData {
    statuses?: OrderStatus[];
    action?: 'created' | 'last_updated' | 'deleted';
    at_start?: number;
    at_end?: number;
    by?: string;
    text_search?: string;
    pageNumber?: number;
    pageSize?: number;
    bookings?: boolean;
}

const useOrdersKey = 'useOrdersKey';
export const useOrders = (
    params: UseOrdersData = {},
    options: CustomQueryOptions<ReturnType<typeof OrdersService.getOrders>> = {}
) => {
    const newParams = { ...params, pageSize: 1000 };
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useOrdersKey, newParams],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                params.action,
                params.at_start,
                params.at_end,
                undefined,
                params.by,
                params.statuses,
                params.text_search,
                params.bookings,
                newParams.pageNumber,
                newParams.pageSize || 1000
            );
        },
        ...options,
    });

    const parsedData = useMemo(
        () => data?.items?.map(getOrderItemDtoToOrder),
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useOrdersLazyKey = 'useOrdersLazyKey';
export const useOrdersLazy = (
    params: UseOrdersData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof OrdersService.getOrders>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 5;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useOrdersLazyKey, params],
        queryFn: async ({ pageParam = 0 }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                params.action,
                params.at_start,
                params.at_end,
                undefined,
                params.by,
                params.statuses,
                params.text_search,
                params.bookings,
                pageParam,
                pageSize
            );
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage.items || lastPage.items.length < pageSize)
                return undefined;
            return allPages.length;
        },
        ...options,
    });

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getOrderItemDtoToOrder)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useOrderKey = 'useOrderKey';
export const useOrder = (
    id?: string,
    options: CustomQueryOptions<ReturnType<typeof OrdersService.getOrder>> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useOrderKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrder(id as string, authorization);
        },
        enabled: !!id,
        ...options,
    });

    return {
        data: useMemo(
            () => (data ? getOrderItemDtoToOrder(data) : data),
            [data]
        ),
        ...other,
    };
};

export const useUpdateOrderBooking = () => {
    const queryClient = useQueryClient();
    const queriesData = queryClient.getQueriesData({
        queryKey: ['OrdersServiceGetOrder'],
        exact: false,
    });

    const update = (
        bookingId: string,
        callback: (
            b: GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto
        ) => GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto
    ) => {
        queriesData.forEach(([queryKey]) => {
            queryClient.setQueryData<GetOrderResDto>(
                queryKey,
                // @ts-ignore
                order => {
                    if (!order) return undefined;
                    const bookings =
                        order.bookings?.map(b =>
                            b.id === bookingId ? callback(b) : b
                        ) || [];
                    return {
                        ...order,
                        bookings,
                    };
                }
            );
        });
    };

    return update;
};

export const useExport = (
    options?: CustomMutationOptions<
        {
            id: string;
        },
        ReturnType<typeof OrdersService.exportDetailsOrders>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        async ({ id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.exportDetailsOrders(id, authorization);
        },
        {
            onSuccess: data => {
                window.open(data.url, '_blank')?.focus();
            },
            ...options,
        }
    );
};
