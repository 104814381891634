import React, { forwardRef, useMemo, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import {
    Booking,
    BookingStatus,
    PaymentStatus,
    SourceType,
    useCancelBooking,
} from '@travelity/api';
import { formatValue } from '@travelity/form';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Card, Heading, IconButton, IconButtonGroup, Tag } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';
import { MoveBookingModal } from '../move-booking-modal';
import { ReasonModal } from '../booking-item/components/reason-modal';
import { OverlayWithReason } from '../overlay-with-reason';
import { ReactComponent as OrderIcon } from '../../assets/order-icon.svg';
import { paxTotal, useNavigateAbsolute } from '../../hooks';
import { useResourcePermissions } from '../../contexts/user';

export interface EventBookingItemProps {
    booking: Booking;
    isSelected: boolean;
    eventId: string;
    refetch: () => void;
}

const EventBookingItem = forwardRef<HTMLDivElement, EventBookingItemProps>(
    ({ booking, isSelected, eventId, refetch }, ref) => {
        const { t } = useTranslation();
        const { enqueueSnackbar } = useSnackbar();

        const [moveBooking, setMoveBooking] = useState(false);

        const [deletingBooking, setDeletingBooking] = useState<string>();
        const { mutate: removeBooking, isLoading: isRemoveBookingLoading } =
            useCancelBooking({
                onSuccess: () => {
                    setDeletingBooking(undefined);
                    refetch();
                    enqueueSnackbar(
                        `Cancelled the booking for the product "${booking.product.name}"`,
                        {
                            variant: 'success',
                        }
                    );
                },
                onError: () => {
                    enqueueSnackbar(
                        `Failed to cancel the booking for the product "${booking.product.name}"`,
                        {
                            variant: 'error',
                        }
                    );
                },
            });
        const options = useMemo(
            () =>
                booking.product.options?.map(({ name, pax }) => ({
                    name,
                    count: paxTotal(pax),
                })),
            []
        );

        const navigate = useNavigateAbsolute();
        const source = useMemo(() => {
            if (booking.source.type === SourceType.DIRECT) {
                // @ts-ignore
                return t(booking.source.name, { ns: 'team' });
            }
            return booking.source.name;
        }, [booking.source]);
        const permissions = useResourcePermissions('booking');

        return (
            <>
                <Card
                    ref={ref}
                    isSelected={isSelected}
                    leftAdornment={
                        <Box
                            sx={{
                                width: '88px',
                                height: '88px',
                                flexShrink: 0,
                            }}
                        />
                    }
                    buttons={
                        <IconButtonGroup>
                            <IconButton
                                href={`/events/${eventId}/booking/${booking.id}`}
                                icon={<VisibilityIcon fontSize="small" />}
                                tooltip="Preview"
                            />
                            {booking.status === BookingStatus.CANCELLED ? (
                                <>
                                    <Divider sx={{ mx: 0.75 }} />
                                    <IconButton
                                        href={`/order/${booking.orderId}`}
                                        icon={<OrderIcon fontSize="small" />}
                                        tooltip="Go to Order"
                                    />
                                </>
                            ) : (
                                <>
                                    {permissions.update && (
                                        <>
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                onClick={() => {
                                                    setMoveBooking(true);
                                                }}
                                                icon={
                                                    <ExitToAppIcon fontSize="small" />
                                                }
                                                tooltip="Move"
                                            />
                                        </>
                                    )}
                                    {permissions.delete && (
                                        <>
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                hoverColor="error.main"
                                                onClick={() =>
                                                    setDeletingBooking(
                                                        booking.id
                                                    )
                                                }
                                                icon={
                                                    <DeleteIcon fontSize="small" />
                                                }
                                                tooltip="Cancel"
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </IconButtonGroup>
                    }
                >
                    <Box
                        component={PerfectScrollbar}
                        sx={{
                            '&&': { height: '46px' },
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                        }}
                        option={{
                            suppressScrollY: true,
                        }}
                    >
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                px: 2,
                                py: 1,
                            }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography
                                sx={{
                                    color: '#B7BCC7',
                                    fontSize: '12px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                Info badges
                            </Typography>
                            <Stack direction="row" gap={1}>
                                {source && (
                                    <Tag label="Source" value={source} />
                                )}
                                {booking.participants?.customer.fullName && (
                                    <Tag
                                        label="Customer"
                                        value={
                                            booking.participants?.customer
                                                .fullName
                                        }
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider />
                    <Stack
                        gap={1}
                        direction="row"
                        sx={{
                            filter:
                                booking.status === BookingStatus.CANCELLED
                                    ? 'blur(2px)'
                                    : undefined,
                        }}
                    >
                        <Stack
                            sx={{
                                width: '82px',
                                py: 1,
                                px: 1,
                                borderRight: '1px solid #D6DAE4',
                            }}
                            gap={0.5}
                        >
                            <Stack
                                sx={{
                                    height: '50px',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                <ConfirmationNumberIcon
                                    sx={{
                                        color:
                                            booking.status ===
                                            BookingStatus.CONFIRMED
                                                ? '#2CAC60'
                                                : '#EC8031',
                                        fontSize: '24px',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        color: '#B2B9CD',
                                    }}
                                >
                                    {t(booking.status, {
                                        ns: 'booking',
                                    })}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Stack
                                sx={{
                                    height: '50px',
                                }}
                                justifyContent="center"
                                alignItems="center"
                                gap={0.5}
                            >
                                <MonetizationOnIcon
                                    sx={{
                                        color:
                                            booking.financials.debt === 0
                                                ? '#2CAC60'
                                                : '#EC8031',
                                        fontSize: '24px',
                                    }}
                                />
                                <Heading
                                    sx={{
                                        fontSize: '12px',
                                        maxWidth: '100%',
                                    }}
                                    alpha={0.4}
                                    ellipsis
                                >
                                    {t(
                                        booking.financials.debt === 0
                                            ? PaymentStatus.PAID
                                            : PaymentStatus.NOT_PAID,
                                        {
                                            ns: 'booking',
                                        }
                                    )}
                                </Heading>
                            </Stack>
                        </Stack>
                        <Stack sx={{ px: 2, py: 1 }} flexGrow={2}>
                            <SingleLineKeyValue
                                label="Order #"
                                darkValue
                                value={`${booking.orderNumber}`}
                                onValueClick={() =>
                                    navigate(`/order/${booking.orderId}`)
                                }
                            />
                            <Divider sx={{ marginTop: 1 }} />
                            <SingleLineKeyValue
                                label="PAX"
                                darkValue
                                value={`${formatValue(
                                    booking.participants.pax
                                )}`}
                            />
                            <Divider sx={{ marginTop: 1 }} />
                            <SingleLineKeyValue
                                label="Notes"
                                darkValue
                                value={`${booking.notes?.length || 0}`}
                            />
                        </Stack>
                    </Stack>
                    {!!options?.length && (
                        <>
                            <Divider />
                            <Stack
                                direction="row"
                                gap={0.5}
                                sx={{ px: 2, py: 1.5 }}
                            >
                                {options.map(option => (
                                    <Tag
                                        label={option.name}
                                        value={option.count}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                    {booking.status === BookingStatus.CANCELLED && (
                        <OverlayWithReason
                            title="Cancelled"
                            reason={booking.reason}
                        />
                    )}
                </Card>
                <ReasonModal
                    title="Are you sure you want to cancel this booking?"
                    content="Provide the reason for cancelling this booking"
                    confirmText="Confirm"
                    open={!!deletingBooking}
                    isLoading={isRemoveBookingLoading}
                    handleCancel={() => setDeletingBooking(undefined)}
                    handleConfirm={message => {
                        removeBooking({
                            bookingId: deletingBooking as string,
                            reason: message,
                        });
                    }}
                />
                <MoveBookingModal
                    handleCancel={() => setMoveBooking(false)}
                    handleConfirm={() => {
                        refetch();
                        setMoveBooking(false);
                    }}
                    booking={booking}
                    open={moveBooking}
                />
            </>
        );
    }
);

export default EventBookingItem;
