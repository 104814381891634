import React, { useMemo, useState } from 'react';
import { CSSObject, Theme, useTheme } from '@mui/material/styles';
import { Box, Divider, Drawer, useMediaQuery } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
// import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { User } from '@travelity/api';
import { ReactComponent as OrderIcon } from '../../../assets/order-icon.svg';
import { ReactComponent as ProductIcon } from '../../../assets/product-icon.svg';
import LogoSection from '../logo-section';
import NavItem from './nav-item/nav-item';
import { MultiButton, MultiButtonItem } from '../../../components/multi-button';
import { BookingModal } from '../../../components/booking-modal';
import { TeamModal } from '../../../components/team-modal';

type SidebarProps = {
    user?: User;
    drawerOpen: boolean;
    drawerToggle: (state: boolean) => void;
};

const openedMixin = (theme: Theme): CSSObject => ({
    width: 224,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

function Sidebar({ drawerOpen, drawerToggle, user }: SidebarProps) {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [bookingOpen, setBookingOpen] = useState(false);
    const [teamOpen, setTeamOpen] = useState(false);

    const addResourceList = useMemo(() => {
        const list: MultiButtonItem[] = [];
        const resources = user?.visibleResources || [];

        if (user?.roleAccess.booking?.create) {
            list.push({
                label: 'New Booking',
                Icon: CalendarMonthIcon,
                // path: '/booking/add',
                onClick: () => setBookingOpen(true),
            });
        }
        if (user?.roleAccess.product?.create) {
            list.push({
                label: 'New Product',
                nativeIcon: true,
                Icon: ProductIcon,
                path: '/products/add',
            });
        }
        // if (resources.includes('route')) {
        //     list.push({
        //         label: 'New Route',
        //         Icon: DirectionsRoundedIcon,
        //         path: '/route/add',
        //     });
        // }
        // if (resources.includes('customer')) {
        //     list.push({
        //         label: 'New Customer',
        //         Icon: PersonPinRoundedIcon,
        //         path: '/customer/add',
        //     });
        // }
        if (user?.roleAccess.team?.create || !resources.includes('team')) {
            list.push({
                label: 'New Team',
                Icon: PersonAddAlt1Icon,
                onClick: () => setTeamOpen(true),
                disabled: !resources.includes('team'),
            });
        }

        return list;
    }, [user?.visibleResources, user?.roleAccess]);

    const showAvailability = user?.roleAccess.booking?.create;
    const showEvent = user?.roleAccess.event?.read;
    const showScheduling = showAvailability || showEvent;

    const lockAvailability =
        !user?.visibleResources?.includes('availability') && false;
    const lockEvent = !user?.visibleResources?.includes('event');

    const showProduct = user?.roleAccess.product?.read;
    const showOrder = user?.roleAccess.order?.read;
    const showBooking = user?.roleAccess.booking?.read;
    const showResources = showProduct || showOrder || showBooking;

    const lockProduct = !user?.visibleResources?.includes('product');
    const lockOrder = !user?.visibleResources?.includes('order');
    const lockBooking = !user?.visibleResources?.includes('booking');

    const showTeam = user?.roleAccess.team?.read;
    const showCollaboration = showTeam;

    const lockTeam = !user?.visibleResources?.includes('team');

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? 224 : 'auto',
            }}
            aria-label="mailbox folders"
            className="hide-on-print"
        >
            <Drawer
                // container={container}
                variant={matchUpMd ? 'persistent' : 'persistent'}
                // anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    ...(drawerOpen ? openedMixin(theme) : closedMixin(theme)),
                    '& .MuiDrawer-paper': {
                        ...(drawerOpen
                            ? openedMixin(theme)
                            : closedMixin(theme)),
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRadius: 0,
                        // borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '64px',
                        },
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                        <LogoSection />
                    </Box>
                </Box>

                <Box>
                    {!!addResourceList.length && (
                        <Box
                            sx={{
                                mx: 2,
                                my: 3,
                            }}
                        >
                            <MultiButton listItems={addResourceList} />
                        </Box>
                    )}
                    {showScheduling && (
                        <>
                            <Divider
                                sx={{
                                    mx: 2,
                                    '& .MuiDivider-wrapper': {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#CACEDC',
                                    },
                                }}
                            >
                                Scheduling
                            </Divider>
                            {showAvailability && (
                                <NavItem
                                    url="/availability"
                                    title="Availability Calendar"
                                    icon={CalendarMonthIcon}
                                    pattern="/availability/*"
                                    disabled={lockAvailability}
                                />
                            )}
                            {showEvent && (
                                <NavItem
                                    url="/events"
                                    title="Daily View"
                                    icon={TodayIcon}
                                    pattern="/events/*"
                                    disabled={lockEvent}
                                />
                            )}
                        </>
                    )}
                    {showResources && (
                        <>
                            <Divider
                                sx={{
                                    mx: 2,
                                    '& .MuiDivider-wrapper': {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#CACEDC',
                                    },
                                }}
                            >
                                Resources
                            </Divider>
                            {showProduct && (
                                <NavItem
                                    url="/products"
                                    title="Products"
                                    nativeIcon
                                    icon={ProductIcon}
                                    pattern="/products/*"
                                    disabled={lockProduct}
                                />
                            )}
                            {showOrder && (
                                <NavItem
                                    url="/orders"
                                    title="Orders"
                                    nativeIcon
                                    icon={OrderIcon}
                                    pattern="/orders/*"
                                    disabled={lockOrder}
                                />
                            )}
                            {showBooking && (
                                <NavItem
                                    url="/bookings"
                                    title="Bookings"
                                    icon={ConfirmationNumberIcon}
                                    pattern="/bookings/*"
                                    disabled={lockBooking}
                                />
                            )}
                        </>
                    )}
                    {showCollaboration && (
                        <>
                            <Divider
                                sx={{
                                    mx: 2,
                                    '& .MuiDivider-wrapper': {
                                        fontSize: '14px',
                                        fontWeight: 600,
                                        color: '#CACEDC',
                                    },
                                }}
                            >
                                Collaboration
                            </Divider>
                            {showTeam && (
                                <NavItem
                                    url="/teams"
                                    title="My Teams"
                                    nativeIcon
                                    icon={ProductIcon}
                                    pattern="/teams/*"
                                    disabled={lockTeam}
                                />
                            )}
                        </>
                    )}
                    {/* <NavItem */}
                    {/*    url="/routes" */}
                    {/*    title="Routes" */}
                    {/*    icon={DirectionsRoundedIcon} */}
                    {/*    pattern="/routes/*" */}
                    {/* /> */}
                    {/* <NavItem */}
                    {/*    url="/customers" */}
                    {/*    title="Customers" */}
                    {/*    icon={PersonPinRoundedIcon} */}
                    {/*    pattern="/customers/*" */}
                    {/* /> */}
                </Box>
            </Drawer>
            {bookingOpen && (
                <BookingModal
                    handleCancel={() => setBookingOpen(false)}
                    // handleConfirm={() => {}}
                    open={bookingOpen}
                />
            )}
            {teamOpen && (
                <TeamModal
                    handleCancel={() => {
                        setTeamOpen(false);
                    }}
                    // handleConfirm={() => {}}
                    open={teamOpen}
                />
            )}
        </Box>
    );
}

export default React.memo(Sidebar);
