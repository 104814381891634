/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomerReqDto } from '../models/CreateCustomerReqDto';
import type { CreateCustomerResDto } from '../models/CreateCustomerResDto';
import type { GetCustomer1ResDto } from '../models/GetCustomer1ResDto';
import type { GetCustomerResDto } from '../models/GetCustomerResDto';
import type { UpdateCustomerReqDto } from '../models/UpdateCustomerReqDto';
import type { UpdateCustomerResDto } from '../models/UpdateCustomerResDto';
import type { UpdateUserReqProfileAttachmentsDto } from '../models/UpdateUserReqProfileAttachmentsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * Get Customer
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param name name of the customer(s) being searched
     * @param email Email of the customer being searched
     * @param phoneNumber phone number of the customer being searched
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetCustomerResDto Successful Response
     * @throws ApiError
     */
    public static getCustomer(
authorization: string,
name?: string,
email?: string,
phoneNumber?: string,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetCustomerResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers',
            headers: {
                'authorization': authorization,
            },
            query: {
                'name': name,
                'email': email,
                'phone_number': phoneNumber,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create a Customer
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateCustomerResDto Successful Response
     * @throws ApiError
     */
    public static createCustomer(
authorization: string,
requestBody: CreateCustomerReqDto,
): CancelablePromise<CreateCustomerResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer by ID
     * @param customerId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetCustomer1ResDto Successful Response
     * @throws ApiError
     */
    public static getCustomer1(
customerId: string,
authorization: string,
): CancelablePromise<GetCustomer1ResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Customer by ID
     * @param customerId ID of the product being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateCustomerResDto Successful Response
     * @throws ApiError
     */
    public static updateCustomer(
customerId: string,
authorization: string,
requestBody: UpdateCustomerReqDto,
): CancelablePromise<UpdateCustomerResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add customer attachment
     * @param customerId ID of the booking being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateCustomerResDto Successful Response
     * @throws ApiError
     */
    public static updateAttachmentsCustomers(
customerId: string,
authorization: string,
requestBody: UpdateUserReqProfileAttachmentsDto,
): CancelablePromise<UpdateCustomerResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/customers/{customer_id}/attachments',
            path: {
                'customer_id': customerId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
