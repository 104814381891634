/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelEventsReqDto } from '../models/CancelEventsReqDto';
import type { CancelEventsRes1Dto } from '../models/CancelEventsRes1Dto';
import type { CancelEventsResDto } from '../models/CancelEventsResDto';
import type { CreateEventReq1Dto } from '../models/CreateEventReq1Dto';
import type { CreateEventReqDto } from '../models/CreateEventReqDto';
import type { CreateEventRes1Dto } from '../models/CreateEventRes1Dto';
import type { CreateEventResDto } from '../models/CreateEventResDto';
import type { EventStatus } from '../models/EventStatus';
import type { GetEventRes1Dto } from '../models/GetEventRes1Dto';
import type { GetEventResDto } from '../models/GetEventResDto';
import type { GetEventsResDto } from '../models/GetEventsResDto';
import type { PeriodData } from '../models/PeriodData';
import type { UpdateAssetsEventsReq1Dto } from '../models/UpdateAssetsEventsReq1Dto';
import type { UpdateAssetsEventsReqDto } from '../models/UpdateAssetsEventsReqDto';
import type { UpdateStaffEventsReq1Dto } from '../models/UpdateStaffEventsReq1Dto';
import type { UpdateStaffEventsReqDto } from '../models/UpdateStaffEventsReqDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * List/Filter Events
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param action action being filtered
     * @param atStart start/end of the time range being filtered
     * @param atEnd start/end of the time range being filtered
     * @param byId name of the person who's action is being filtered
     * @param byName name of the person who's action is being filtered
     * @param types list of filtered product types
     * @param statuses list of filtered statuses
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param textSearch searched term
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @param startAtId Id of the event to start the next page with
     * @param bookings Indicates to fetch bookings for each event
     * @returns GetEventsResDto Successful Response
     * @throws ApiError
     */
    public static getEvents(
authorization: string,
action?: 'created' | 'last_updated' | 'deleted',
atStart?: number,
atEnd?: number,
byId?: string,
byName?: string,
types?: Array<string>,
statuses?: Array<EventStatus>,
startTs?: number,
endTs?: number,
textSearch?: string,
pageNumber?: number,
pageSize: number = 20,
startAtId?: string,
bookings: boolean = false,
): CancelablePromise<GetEventsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events',
            headers: {
                'authorization': authorization,
            },
            query: {
                'action': action,
                'at_start': atStart,
                'at_end': atEnd,
                'by_id': byId,
                'by_name': byName,
                'types': types,
                'statuses': statuses,
                'start_ts': startTs,
                'end_ts': endTs,
                'text_search': textSearch,
                'page_number': pageNumber,
                'page_size': pageSize,
                'start_at_id': startAtId,
                'bookings': bookings,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createEvent(
authorization: string,
requestBody: (CreateEventReqDto | CreateEventReq1Dto),
): CancelablePromise<(CreateEventResDto | CreateEventRes1Dto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch event
     * @param eventId ID of the event being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getEvent(
eventId: string,
authorization: string,
): CancelablePromise<(GetEventResDto | GetEventRes1Dto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/{event_id}',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Event
     * @param eventId ID of the event being canceled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelEvents(
eventId: string,
authorization: string,
requestBody: CancelEventsReqDto,
): CancelablePromise<(CancelEventsResDto | CancelEventsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/cancel',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update event date
     * @param eventId ID of the event being updated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateDateEvents(
eventId: string,
authorization: string,
requestBody: PeriodData,
): CancelablePromise<(CancelEventsResDto | CancelEventsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/date',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update event staff
     * @param eventId ID of the event being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateStaffEvents(
eventId: string,
authorization: string,
requestBody: (UpdateStaffEventsReqDto | UpdateStaffEventsReq1Dto),
): CancelablePromise<(CancelEventsResDto | CancelEventsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/staff',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update event assets
     * @param eventId ID of the event being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateAssetsEvents(
eventId: string,
authorization: string,
requestBody: (UpdateAssetsEventsReqDto | UpdateAssetsEventsReq1Dto),
): CancelablePromise<(CancelEventsResDto | CancelEventsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/assets',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update event capacity
     * @param eventId ID of the event being fetched
     * @param capacityId Id of the product's capacity option
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCapacityEvents(
eventId: string,
capacityId: string,
authorization: string,
): CancelablePromise<(CancelEventsResDto | CancelEventsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/capacity/{capacity_id}',
            path: {
                'event_id': eventId,
                'capacity_id': capacityId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
