/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BookingNoteVisibility {
    INTERNAL = 'internal',
    CUSTOMER = 'customer',
}
